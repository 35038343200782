<template>
  <section id="section-experience">
    <div class="container">
      <h2>Career & Experience</h2>
      <WorkExperience
        v-for="workExperience in workExperienceList"
        :key="workExperience.company"
        :work-experience="workExperience"
      />
    </div>
  </section>
</template>

<script>
import workExperienceJSON from "@/data/work-experience.json";
import WorkExperience from "@/components/WorkExperience";

export default {
  components: { WorkExperience },
  data: () => ({
    workExperienceList: workExperienceJSON
  })
};
</script>

<style lang="scss" scoped>
@import "src/styles/variables";
@import "src/styles/mixins";

section {
  background: $light;
  overflow: auto;

  h2 {
    text-align: center;
  }
}

@include desktop {
  h2 {
    margin: 2rem 0;
    text-align: left;
  }
}
</style>
