<template>
  <section>
    <div>
      <img src="@/assets/headshot.png" alt="Noah side profile" />
      <h1>Noah Fernandes</h1>
      <div class="divider"></div>
      <h2>Front-end Engineer</h2>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import "src/styles/variables";
@import "src/styles/mixins";

$image-size: 12rem;

section {
  text-align: center;
  height: 85vh;
  background: $warm;
  display: flex;
  align-items: center;
  justify-content: center;
}

img {
  width: $image-size;
  height: $image-size;
  border-radius: 50%;
  margin-bottom: 2rem;
}

h1 {
  font-size: 1.84rem;
  font-weight: 400;
  margin: 0;
  line-height: 1.2;
}

.divider {
  height: 2px;
  background: $dark;
}

h2 {
  margin: 0;
}

@include desktop {
  $image-size: 12rem;

  img {
    width: $image-size;
    height: $image-size;
  }
}
</style>
