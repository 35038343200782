<template>
  <section>
    <div class="container">
      <div class="amsterdam-map"></div>
      <h2>Contact</h2>
      <p>
        Noah is a freelancer based in good ol' Amsterdam.
      </p>
      <p>
        Reach out &
        <a href="mailto:noahdecoco@gmail.com">drop him a message</a> if you want
        him on your team!
      </p>
      <div class="social-buttons">
        <a href="mailto:noahdecoco@gmail.com">
          <img src="@/assets/icons/email.svg" alt="Email icon" />
        </a>
        <a href="https://twitter.com/noahdecoco" target="_blank">
          <img src="@/assets/icons/twitter.svg" alt="Twitter icon" />
        </a>
        <a href="https://www.linkedin.com/in/noah-fernandes" target="_blank">
          <img src="@/assets/icons/linkedin.svg" alt="LinkedIn icon" />
        </a>
        <a href="https://github.com/noahdecoco" target="_blank">
          <img src="@/assets/icons/github.svg" alt="GitHub icon" />
        </a>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import "src/styles/variables";
@import "src/styles/mixins";

section {
  background: $dark;
  overflow: auto;
  color: $warm;
  text-align: center;
  padding: 0 0 4rem;
  border-bottom: 3px solid $warm;
}

.amsterdam-map {
  width: 100%;
  padding-bottom: 64%;
  opacity: 0.65;
  background: url("../assets/amsterdam.png") no-repeat center;
  background-size: cover;
}

a {
  color: $cool;
}

.social-buttons {
  margin-top: 2rem;

  a {
    width: 2.6rem;
    height: 2.6rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: none;
    border-radius: 50%;
    border: 1px solid transparentize($warm, 0.6);
    margin: 0 0.4rem;

    img {
      width: 1.2rem;
    }
  }
}

@include tablet {
  section {
    padding: 2rem 0 4rem;
  }

  .amsterdam-map {
    margin: auto;
    width: 80%;
    padding-bottom: 33%;
  }
}
</style>
