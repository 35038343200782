<template>
  <SectionHero />
  <SectionExperience />
  <SectionContact />
</template>

<script>
import SectionHero from "@/components/SectionHero";
import SectionExperience from "@/components/SectionExperience";
import SectionContact from "@/components/SectionContact";

export default {
  name: "App",
  components: { SectionHero, SectionExperience, SectionContact }
};
</script>

<style lang="scss">
@import "styles/variables";
@import "src/styles/mixins";

html,
body {
  margin: 0;
  padding: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $dark;
  font-size: $base-font-size;
}

.container {
  max-width: $app-max-width;
  width: 100%;
  margin: auto;
  padding: 1rem;
  box-sizing: border-box;
}

// .header {
//   display: none;
// }

// @include desktop {
//   #app {
//     // padding-top: $header-height;
//   }

//   .header {
//     display: block;
//     position: fixed;
//     top: 0;
//     width: 100vw;
//   }
// }
</style>
