<template>
  <div class="work-experience">
    <div class="logo-and-status">
      <div class="logo-wrapper">
        <img
          class="company-logo"
          :src="require(`@/assets/${workExperience.logo}`)"
          :alt="workExperience.company"
        />
      </div>

      <div class="status-wrapper">
        <h3 class="job-title">{{ workExperience.title }}</h3>
        <h4 class="company-employment">
          {{ workExperience.company }} · {{ workExperience.employment }}
        </h4>
        <span class="employment-duration">
          {{ workExperience.duration }}
        </span>
        <ul class="technology-list">
          <li
            v-for="technology in workExperience.technologies"
            :key="technology"
          >
            {{ technology }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    workExperience: Object
  }
};
</script>

<style lang="scss" scoped>
@import "src/styles/variables";
@import "src/styles/mixins";

.work-experience {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.logo-and-status {
  display: flex;
  flex: 1;

  .status-wrapper {
    flex: 1;
  }
}

.work-description p {
  margin-top: 1rem;
}

.technology-list {
  display: flex;
  margin: 0.1rem 0 0;
  padding: 0.1rem 0 0;
  border-top: 1px solid $dark;
  list-style: none;
  font-size: 0.86rem;

  li {
    &:not(:last-child)::after {
      content: "·";
      padding: 0 0.3rem;
    }
  }
}

.company-logo {
  width: 5rem;
  margin-right: 1rem;
}

.job-title {
  margin: 0;
  font-size: 1rem;
}

.company-employment {
  margin: 0;
  font-size: 0.9rem;
}

.employment-duration {
  display: block;
  font-size: 0.9rem;
}

@include tablet {
  .work-experience {
    display: flex;
    margin-bottom: 3rem;
  }

  .logo-and-status {
    width: 50%;
    flex: 0 1 auto;

    .status-wrapper {
      padding-right: 2rem;
    }
  }

  .work-description {
    width: 7/12 * 100%;

    p {
      margin: 0;
    }
  }

  .company-logo {
    margin-right: 1.6rem;
  }
}
</style>
